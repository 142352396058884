import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { PanelHeader, Spinner } from '../../components';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { reportProjectsNotes } from '../../helpers/actions/reports';
import { getUrlParam } from '../../helpers/urlParams';

class ReportNotes extends Component {
  pages = [];

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      project: {}
    };
  }

  load() {
    this.setState({ loading: true });

    let { dispatch } = this.props;

    dispatch(reportProjectsNotes())
      .then(params => this.setState({ params, loading: false }))
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  render() {
    let { loading, params } = this.state;
    let type = getUrlParam('type', '');
    let provider = getUrlParam('provider', '');
    let supervisor = getUrlParam('supervisor', '');
    let date = getUrlParam('date', '');

    let url = null,
      filename = null;
    if (params) {
      url = `${params.url}?apiKey=${params.httpHeaders.Authorization.replace(
        'Bearer ',
        ''
      )}&type=${type}&provider=${provider}&supervisor=${supervisor}&date=${date}`;
    }

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody className="pdf-full">
              {url ? (
                <iframe title={filename} className="pdf-holder" src={url} />
              ) : null}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(ReportNotes));
