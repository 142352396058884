import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../../components';
import { all, remove } from '../../../helpers/actions/clients';
import ClientCard from './ClientCard';
import ClientModal from './ClientModal';

class Clients extends Component {
  state = {
    loading: true,
    data: [],
    count: 0,
    page: 1,
    limit: 18,
    filters: {},
    search: '',
    client: null,
    clientDelete: null
  };

  load() {
    this.setState({ loading: true });

    let { page, limit, search } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search }))
      .then(({ data, meta }) =>
        this.setState({ data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  filter(search) {
    this.setState({ search, page: 1 }, () => this.load());
  }

  deleteClient(id) {
    this.setState({ loading: true, clientDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
      loading,
      data,
      page,
      limit,
      count,
      client,
      clientDelete
    } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event => this.filter(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={8}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ client: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {data.length > 0 ? (
            <div>
              <Row>
                {data.map((contact, key) => (
                  <Col key={key} xs={12} md={6} lg={4}>
                    <ClientCard
                      client={contact}
                      onEdit={clientEdit =>
                        this.setState({ client: clientEdit })
                      }
                      onDelete={deleteClient =>
                        this.setState({ clientDelete: deleteClient })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No clients found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {!!client ? (
            <ClientModal
              show={!!client}
              client={client}
              dispatch={this.props.dispatch}
              onCancel={() => this.setState({ client: null })}
              onConfirm={() =>
                this.setState({ client: null }, () => this.load())
              }
            />
          ) : null}

          {!clientDelete ? null : (
            <AlertConfirm
              message={'The client cannot be recovered'}
              onCancel={() => this.setState({ clientDelete: null })}
              onConfirm={() => this.deleteClient(clientDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(Clients));
